<template>
  <div class="definition-card">
    <h2 class="definition-card-title mt-0">
      {{ title }}
    </h2>
    <p class="definition-card-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Definition",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.definition-card {
  background: rgba($color: $focus_color, $alpha: 0.078);
  border: 1px dotted gray;
  border-radius: 10px;
  padding: 25px;

  h2 {
    border-bottom: 1px dotted gray;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 25px;
    padding-bottom: 25px;
  }

  &-text {
    color: #212529;
    font-size: 14px;
    margin-bottom: 0px;

    @media (min-width: 1200px) {
      font-size: 16px;
    }

    @media (min-width: 1600px) {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 18px;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 22px;
    }
  }
}
</style>
